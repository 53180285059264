// MOBILE MENU
const menu = document.querySelector('#site-navigation')
const menuToggle = document.querySelector('#menu-toggle')
const menuClose = document.querySelector('#menu-close')

menuToggle.addEventListener('click', function () {
  menu.classList.toggle('is-open')
})

menuClose.addEventListener('click', function () {
  menu.classList.remove('is-open')
})

/* 

lightGallery(document.getElementById('animated-thumbnails-gallery'), {
  thumbnail: true,
  plugins: [lgZoom, lgThumbnail, lgAutoplay],
})

lightGallery(document.getElementById('customize-thumbnails-gallery'), {
  thumbnail: true,
  // Add a custom class to apply style only for the particular gallery
  addClass: 'lg-custom-thumbnails',

  // Remove the starting animations.
  // This can be done by overriding CSS as well
  appendThumbnailsTo: '.lg-outer',

  animateThumb: true,
  allowMediaOverlap: true,
})
 */
